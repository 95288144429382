.obvrev-detail-container {
    font-size: 1.00vw;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.2vw;
    grid-auto-rows:grid-auto-rows;
    grid-template-areas:
        "a b";
  
    background-image: linear-gradient(92.88deg, #e2e3e7 9.16%, #eeeff5 43.89%, #e2e3e7 64.72%);
    border-radius: 8vw;
    border-style: none;
    box-sizing: border-box;
    color: black;
    flex-shrink: 1;
    font-weight: 500;
    max-height: 3.5vw;
    padding: 0.15vw;
    text-align: center;
    /* text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px; */
    transition: all .5s;
    -webkit-user-select: none;
    touch-action: manipulation;
  
    margin:0.1em;
  }
  
  .obvrev-detail-container:hover {
    box-shadow: #173fe25e 0 1px 30px;
    transition-duration: .1s;
  }
  
  @media (min-width: 767px) {
    .modal-container {
      padding: 0 2.6vw;
    }
    .obvrev-detail-container {
      padding: 0 2.2vw;
    } 
  }
  
  
  h1{
    font-size: 2.5vw;
  }
  
  .modal-container {
      align-items: center;
  }
  
  .obvrev-diagram {
      height: auto;
      max-width: 15vw; /* Adjusted max-width */
      display:block;
      margin:auto;
    }
  
  .obvrev-description-container {
      font-size: 1.15vw;
      margin-top: 0.25vw;
      margin-bottom: 0.25vw;
  }
  
  .obvrev-detail-key {
      grid-area: a;
      font-weight: bold;
      font-size: 1vw;
      margin: auto;
      width: 90%;
      padding: 1.1vw;
      text-align:center;
  }
  
  .obvrev-detail-value {
      grid-area: b;
      font-size: 0.9vw;
      margin: auto;
      width: 90%;
      padding: 1.1vw;
      text-align:center;
  }