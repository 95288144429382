.pattern-header-container {    
    background-color: white;
    margin-left:5%;
    margin-right:5%;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
        "a"
        "b"
        "c"
        "d";
    padding-bottom: 0;
}

.pattern-meaning {
    text-align: center;
    grid-area: b;
    font-size: 1.5vw;
    margin-bottom: 0.75vw;
    text-align: center;
}

.pattern-info-container {
    grid-area: a;
    display: grid;
    gap: 5px;
    grid-template-columns: repeat(4, 1fr); /* Adjusted column width */
    grid-template-areas: "e f g h";
}

.no-movements-container {
    grid-area:f;
    width: 100%;
    max-height: 400px;
    max-width: 400px;

    justify-content: center;
    align-items: center;
    margin: 3vw;
    text-align: center;
}

.no-movements-header {
    vertical-align: top;
    font-size: 1.5vw;
}

.no-movements-value {
    vertical-align: center;
    margin: 0.9vw;
    font-size: 5vw;
}

.pattern-diagram-container {
    grid-area: g;
    width: 100%;
    height: 100%;
    max-height: 400px;
    max-width: 400px;
    margin: 2vw;
    text-align: center;
}

.pattern-diagram-header {
    vertical-align: top;
    font-size: 1.5vw;
}

.pattern-diagram {
    width: auto;
    height: auto;
    max-width: 90%;  /*Adjusted max-width*/
  }

.pattern-diagram-Gae-Beak {
    width: auto;
    height:auto;
    max-height: 90%;
    max-width: 50%; /* Adjusted max-width */
  }

  .pattern-diagram-Eui-Am {
    width: auto;
    height:auto;
    max-height: 90%;
    max-width: 50%; /* Adjusted max-width */
  }