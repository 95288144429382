.information-card-container {
    border: 1px solid grey;
    border-radius: 5px;
    padding: 10px;
    word-wrap: break-word;
    white-space: normal;
    line-height: 1.5;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensure content is evenly spaced */
    min-height: 50px; /* Optional: Ensure a minimum height */
}



.information-card-container:hover{
    transform: scale(1.05);
}
