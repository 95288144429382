.movement-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 0.05vw;
    grid-auto-rows:max-content;
    grid-template-areas:
        "a b b b b z"
        "c c c c c c"
        "c c c c c c";
    background-color: white;

    border-image: (rgba(144, 142, 142, 0.6), #fefefe) 70;
    border-width: 5px;
    border-style:groove;   
    border-radius:10px; 

    padding:0.05vw;
    -moz-osx-font-smoothing:grayscale;
    transform: translateZ(0);
    transition: transform 0.20s ease-out;
    box-shadow: 0px -2px 2px rgba(144, 142, 142, 0.6);
    cursor:grab;
    align-items: start;
    margin-left:10%;
    margin-right:10%;
    font-size: 1.2vw;
}

.movement-container:hover{
    transform: scale(1.01);
}

.movement-container:active{
    transform: scale(0.98);
}

.movement-container.open .movement-detail {
    display: grid;
}
.movement-number{
    font-size: 1.20vw;
    grid-area: a;
}

.movement-description{
    font-size: 1.20vw;
    grid-area:b;
    text-align: left;
}

.movement-tutorial{
    font-size: 1.20vw;
    grid-area:z;
    text-align: right;
    background-color: white;
}

.movement-detail{
    display: none;
    grid-area: c;
    grid-template-columns: repeat(6, 1fr);
    gap: 5px;
    grid-auto-rows: auto;
    grid-template-areas:
        "a a b b c c"
        "d d e e f f ";
    align-items: start;
}

.modal-stance {
    grid-area: a;
    cursor:pointer;
}

/* For when there is no stance */
.movement-stance {
    grid-area: a;
    cursor:pointer;
}

.modal-technique {
    grid-area: b;
    cursor:pointer;
}

.modal-tool {
    grid-area: c;
    cursor:pointer;
}

.movement-tool {
    grid-area: c;
    cursor:pointer;
}

.modal-section {
    grid-area: d;
    cursor:pointer;
}

/* For when there is no stance */
.movement-section {
    grid-area: d;
    cursor:pointer;
}

.movement-obvrev {
    grid-area: e;
}

.modal-obvrev {
    grid-area: e;
}

.movement-motion {
    grid-area: f;
}

.modal-motion {
    grid-area: f;
}

.movement-key{
    border: 1px solid white;
    font-weight: bold;
}

.movement-key:hover{
    border: 1px solid black;
    font-weight: bold;
}

.movement-value{
    font-weight: normal;
}

.pattern-end-container, .pattern-start-container {
    gap: 0.05vw;
    background-color: black;
  
    border-image: (rgba(144, 142, 142, 0.6), #fefefe) 70;
    border-width: 5px;
    border-style:groove;   
    border-radius:10px; 
  
    padding:0.05vw;
    -moz-osx-font-smoothing:grayscale;
    transform: translateZ(0);
    transition: transform 0.20s ease-out;
    box-shadow: 0px -2px 2px rgba(144, 142, 142, 0.6);
    cursor:grab;
    align-items: start;
    margin-left:10%;
    margin-right:10%;
    font-size: 1.2vw;
    color: white;
  }