.techniques-container {
    max-width: 600px; /* Restrict the width */
    margin: 0 auto; /* Centers the container horizontally */
    padding: 20px 0; /* Add small padding to the top and bottom */
    display: flex;
    flex-direction: column; /* Stack items in a column */
    justify-content: flex-start; /* Align items closer to the top */
    align-items: center; /* Center the items horizontally */
    gap: 20px; /* Adds spacing between the stacked items */
    min-height: 100vh; /* Ensures full height of viewport for vertical alignment */
}

.technique-choice {
    width: 100%; /* Make the items take full width of the container */
    padding: 20px;
    text-align: center;
    font-size: 20px;
    border-radius: 8px; /* Optional: Rounds the corners */

    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: bold;
}


/* Left side (starts from the left) */
.technique-choice::before {
    left: 0;
    transform: translateX(-100%); /* Initially hidden off-screen to the left */
}

/* Right side (starts from the right) */
.technique-choice::after {
    right: 0;
    transform: translateX(100%); /* Initially hidden off-screen to the right */
}

/* On hover, both sides sweep towards the center */
.technique-choice:hover::before {
    transform: translateX(0); /* Sweep to the center from the left */
}

.technique-choice:hover::after {
    transform: translateX(0); /* Sweep to the center from the right */
}

/* Ensure the text is above the animation */
.technique-choice > * {
    position: relative;
    z-index: 2; /* Keeps the text above the pseudo-elements */
}

/* On hover, also change the border color */
.technique-choice:hover {
    border-color: #f7f7f7; /* Change the border color */
}

.stances-container {
    position: relative; /* Required for the pseudo-element positioning */
    border-style: double;
    border-color: #f7f130;
    border-width: 3px;
    background: radial-gradient(circle at center, #f7f7f7 80%, #f7f130);
    overflow: hidden; /* Ensures the animation stays inside the box */
    transition: border-color 0.5s ease; /* Smooth transition for border color */
    color: #000; /* Text color */
    z-index: 2; /* Ensure the content has a higher stacking order */
}

/* Left-side animation */
.stances-container::before,
.stances-container::after {
    content: '';
    position: absolute;
    top: 0;
    width: 50%; /* Covers half of the container */
    height: 100%;
    background: radial-gradient(circle at center, #f7f130 80%, #f7f7f7); /* Swapped colors */
    z-index: 1; /* Below the text */
    transition: transform 0.5s ease; /* Animate the sweeping effect */
    mix-blend-mode: multiply; /* Blend with the background */
}

.hand-techniques-container {
    position: relative; /* Required for the pseudo-element positioning */
    border-style: double;
    border-color: #2ee875;
    border-width: 3px;
    background: radial-gradient(circle at center, #f7f7f7 80%, #2ee875);
    overflow: hidden; /* Ensures the animation stays inside the box */
    transition: border-color 0.5s ease; /* Smooth transition for border color */
    color: #000; /* Text color */
    z-index: 2; /* Ensure the content has a higher stacking order */
}

/* Left-side animation */
.hand-techniques-container::before,
.hand-techniques-container::after {
    content: '';
    position: absolute;
    top: 0;
    width: 50%; /* Covers half of the container */
    height: 100%;
    background: radial-gradient(circle at center, #2ee875 80%, #f7f7f7); /* Swapped colors */
    z-index: 1; /* Below the text */
    transition: transform 0.5s ease; /* Animate the sweeping effect */
    mix-blend-mode: multiply; /* Blend with the background */
}

.foot-techniques-container {
    position: relative; /* Required for the pseudo-element positioning */
    border-style: double;
    border-color: #3498db;
    border-width: 3px;
    background: radial-gradient(circle at center, #f7f7f7 80%, #3498db);
    overflow: hidden; /* Ensures the animation stays inside the box */
    transition: border-color 0.5s ease; /* Smooth transition for border color */
    color: #000; /* Text color */
    z-index: 2; /* Ensure the content has a higher stacking order */
}

.foot-techniques-container::before,
.foot-techniques-container::after {
    content: '';
    position: absolute;
    top: 0;
    width: 50%; /* Covers half of the container */
    height: 100%;
    background: radial-gradient(circle at center, #3498db 80%, #f7f7f7); /* Swapped colors */
    z-index: 1; /* Below the text */
    transition: transform 0.5s ease; /* Animate the sweeping effect */
    mix-blend-mode: multiply; /* Blend with the background */
}

.attack-techniques-container {
    position: relative; /* Required for the pseudo-element positioning */
    border-style: double;
    border-color: #d44b10;
    border-width: 3px;
    background: radial-gradient(circle at center, #f7f7f7 80%, #d44b10);
    overflow: hidden; /* Ensures the animation stays inside the box */
    transition: border-color 0.5s ease; /* Smooth transition for border color */
    color: #000; /* Text color */
    z-index: 2; /* Ensure the content has a higher stacking order */
}

.attack-techniques-container::before,
.attack-techniques-container::after {
    content: '';
    position: absolute;
    top: 0;
    width: 50%; /* Covers half of the container */
    height: 100%;
    background: radial-gradient(circle at center, #d44b10 80%, #f7f7f7); /* Swapped colors */
    z-index: 1; /* Below the text */
    transition: transform 0.5s ease; /* Animate the sweeping effect */
    mix-blend-mode: multiply; /* Blend with the background */
}

.defence-techniques-container {
    position: relative; /* Required for the pseudo-element positioning */
    border-style: double;
    border-color: #0e0d0d;
    border-width: 3px;
    background: radial-gradient(circle at center, #f7f7f7 80%, #0e0d0d);
    overflow: hidden; /* Ensures the animation stays inside the box */
    transition: border-color 0.5s ease; /* Smooth transition for border color */
    color: #000; /* Text color */
    z-index: 2; /* Ensure the content has a higher stacking order */
}

.defence-techniques-container::before,
.defence-techniques-container::after {
    content: '';
    position: absolute;
    top: 0;
    width: 50%; /* Covers half of the container */
    height: 100%;
    background: radial-gradient(circle at center, #0e0d0d 20%, #f7f7f7); /* Swapped colors */
    z-index: 1; /* Below the text */
    transition: transform 0.5s ease; /* Animate the sweeping effect */
    mix-blend-mode: multiply; /* Blend with the background */
    color:white;
}