.container {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  text-align: center;
  padding: 10px;
  margin: 0 auto; /* Ensure it's horizontally centered */
  max-width: 90%; /* Prevent it from being too wide on larger screens */
  white-space: pre-line;
  height: 100vh; /* Take up full height of the viewport */
}

.home-page-header {
  line-height: 1.2em;
  font-size: 2em;
  margin-bottom: 20px;
  padding: 5px; /* Slightly increase padding for readability */
}

.home-page-content {
  font-size: 1.2em;
  padding: 10px;
}

/* Responsive design */
@media (min-width: 768px) {
  .home-page-header {
    font-size: 2.5em;
  }
  .home-page-content {
    font-size: 1.4em;
  }
}

@media (min-width: 1024px) {
  .home-page-header {
    font-size: 3em;
  }
  .home-page-content {
    font-size: 1.6em;
  }
}
