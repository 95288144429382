.technique-detail-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  grid-auto-rows:maxs-content;
  grid-template-areas:
      "a b";

  background-image: linear-gradient(92.88deg, #e2e3e7 9.16%, #eeeff5 43.89%, #e2e3e7 64.72%);
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: black;
  flex-shrink: 1;
  font-weight: 500;
  max-height: 3.5rem;
  padding: 0 15rem;
  text-align: center;
  /* text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px; */
  transition: all .5s;
  -webkit-user-select: none;
  touch-action: manipulation;

  margin:0.1em;
}

.technique-detail-container:hover {
  box-shadow: #173fe25e 0 1px 30px;
  transition-duration: .1s;
}

@media (min-width: 767px) {
  .modal-container {
    padding: 0 2.6rem;
  }
  .technique-detail-container {
    padding: 0 2.6rem;
  } 
}


h1{
  font-size: 2.5vw;
}

.modal-container {
    align-items: center;
}

.technique-diagram {
    width: 30%;
    height: auto;
    max-width: 30%; /* Adjusted max-width */
    display:block;
    margin:auto;
  }

.technique-description-container {
    font-size: 1vw;
    margin-top: 0.25vw;
    margin-bottom: 0.25vw;
    white-space: pre-line;
}

.technique-detail-key {
    grid-area: a;
    font-weight: bold;
    margin: auto;
    width: 60%;
    padding: 10px;
    text-align:center;
}

.technique-detail-value {
    grid-area: b;
    margin: auto;
    width: 90%;
    padding: 10px;
    text-align:left;
}

.revealButton {
  border-radius: 8px;
  font-size: 1vw;
  margin: auto;
  width: 100%;
  text-align:center;
  height: 2.5rem;
  flex-shrink: 1;
}

.unclicked {
  background-color: black;
  color: white;
  font-weight:bolder;
}

.clicked {
  background-color: white;
  color: black;
  font-weight:bolder;
}

.technique-techniquetype-container {
  border-radius: 8px;
  padding: 0.5rem;
  margin-top: 0.5rem;
}

.techniquetype-detail-key {
  font-weight: bold;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.techniquetype-detail-value {
  font-size: 1vw;
  padding-left: 1.5rem;
  white-space: pre-line;
}