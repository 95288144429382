/* Default styles (for desktop or larger screens) */
.nav-bar-container {
  margin: 27px auto 0;
  margin-bottom: 15px;
  position: relative;
  width: fit-content;
  height: 50px;
  background-color: #1a1b1d;
  border-radius: 8px;
  font-size: 0;
}
.nav-link {
  line-height: 50px;
  height: 100%;
  font-size: 15px;
  display: inline-block;
  position: relative;
  z-index: 1;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  color: white;
  cursor: pointer;
}
nav .animation {
  position: absolute;
  height: 100%;
  top: 0;
  z-index: 0;
  transition: all .5s ease 0s;
  border-radius: 8px;
}
a:nth-child(1) { width: 150px; }
a:nth-child(2) { width: 150px; }
a:nth-child(3) { width: 150px; }
a:nth-child(4) { width: 150px; }
a:nth-child(5) { width: 150px; }

nav a:nth-child(1):hover~.animation {
  width: 150px;
  left: 0;
  background-color: #f7f130;
}
nav a:nth-child(2):hover~.animation {
  width: 150px;
  left: 150px;
  background-color: #2ee875;
}
nav a:nth-child(3):hover~.animation {
  width: 150px;
  left: 300px;
  background-color: #3498db;
}
nav a:nth-child(4):hover~.animation {
  width: 150px;
  left: 450px;
  background-color: #d44b10;
}
nav a:nth-child(5):hover~.animation {
  width: 150px;
  left: 600px;
  background-color: #0e0d0d;
  color: white;
}

span {
  color: #2BD6B4;
}

/* Mobile view (for screens 768px wide and smaller) */
@media only screen and (max-width: 768px) {
.nav-bar-container {
  width: 100%;
  height: auto;
  margin: 10px 0;
  background-color: #1a1b1d;
}

.nav-link {
  display: block;
  width: 100%;
  text-align: center;
  line-height: 50px;
  font-size: 14px;
  padding: 10px 0;
  border-bottom: 1px solid #333;
}

nav .animation {
  display: none; /* Hide the animation effect on mobile for simplicity */
}

a:nth-child(1),
a:nth-child(2),
a:nth-child(3),
a:nth-child(4),
a:nth-child(5) {
  width: 100%;
}

nav a:nth-child(1):hover~.animation,
nav a:nth-child(2):hover~.animation,
nav a:nth-child(3):hover~.animation,
nav a:nth-child(4):hover~.animation,
nav a:nth-child(5):hover~.animation {
  left: 0;
  background-color: transparent;
}
}
