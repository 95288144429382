.terminology-page {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.terminology-list {
    width: 100%;
    margin: 20px 0;
}

.terminology-headers {
    display: flex;
    background-color: #333;
    color: #fff;
    font-weight: bold;
    padding: 12px 15px;
}

.terminology-name-header {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
}

.terminology-values {
    width: 100%;
}

.terminology-row {
    display: flex;
    border: 1px solid #ddd;
    padding: 12px 15px;
}

.terminology-value {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
    color: #333;
}

.terminology-row:nth-child(even) {
    background-color: #f9f9f9;
}

.terminology-row:hover {
    background-color: #f1f1f1;
}
