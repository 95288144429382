.stance-detail-container {
    max-width: 1200px; /* Maximum width of the content */
    margin: 0 auto; /* Centers the content horizontally */
    padding: 0 20px; /* Adds some padding on smaller screens */
}

.stance-detail-headers {
    text-align: center;
    font-size: larger;
    padding: 10px;
}

.stance-detail-terminology {
    text-decoration: underline;
}

.stance-detail-diagram {
    padding-top: 15px;
    padding-bottom: 15px;

    max-width: 70vw; /* Image takes 80% of the viewport width */
    max-height: 70vh; /* Image takes 80% of the viewport height */
    object-fit: contain; /* Ensures image scales correctly */
}

.stance-headers {
    font-weight: bold;
}

.stance-detail-description {
    padding: 10px;
    white-space: pre-line;
}

.stance-detail-info-container {
    padding:10px;
    display:grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: 
        "a a a a"
        "b b b b"
        "c c c c";
    text-align: center;
    max-width:50%;
    margin: 0 auto; 
}

.stance-detail-weightdist-container {
    grid-area:a;
    padding:10px;
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 
        "wk wv";
}

.stance-weight-key {
    grid-area: wk;
}

.stance-weight-value {
    grid-area: wv;
}

.stance-detail-length-container {
    grid-area:b;
    padding:10px;
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 
        "lk lv";
}

.stance-detail-width-container {
    grid-area:c;
    padding:10px;
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 
        "lk lv";
}

