.App {
  text-align: center;
  background-color: white;

  font-family: "Alegreya", serif;
  font-optical-sizing: auto;
  font-weight:bold ;
  font-style: normal;
}

.app-banner {
  background-color: rgb(102, 102, 112);
  overflow: hidden;
}

.patterns-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; was messing up spacing to the nav-bar. */
  min-height: 100vh; /* This ensures it takes the full height of the viewport */
}